<template>
    <section class="our-team">
        <div class="container">
            <div class="row">
                <h2 class="section-title">{{ $t('our_team.title') }}</h2>
            </div>

            <swiper
                :modules="modules"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :breakpoints = "{
                    320: {
                        slidesPerView: 1,
                        centeredSlides: true,
                        spaceBetween: 20,
                    },
                    1200: {
                        slidesPerView: 2,
                        spaceBetween: 60,
                    },
                }"
                :scrollbar = "{
                    el: '.our-team--swiper-scrollbar',
                    draggable: true,
                }"
                :navigation="{
                    nextEl: '.our-team--swiper-button-next',
                    prevEl: '.our-team--swiper-button-prev',
                }"
            >
                <swiper-slide v-for="(member, index) in members" :key="index">
                    <div class="member-title">
                        <img :src="require(`@/assets/${member.src}`)" alt="" class="member-img shadow">
                        <div class="member-info">
                            <h5 class="member-name">{{ member.name }}</h5>
                            <ul class="member-socials">
                                <li v-for="(link, linkIndex) in member.social" :key="linkIndex">
                                    <a :href="link.href" target="_blank">
                                        <i :class="'fa-brands fa-'+link.name+'-square'"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="member-content">
                        <p v-for="(paragraph , index) in member.contents" :key="index">{{paragraph}}</p>
                    </div>
                </swiper-slide>
                <div class="our-team--swiper-controller swiper-controller">
                    <div class="our-team--swiper-scrollbar swiper-scrollbar"></div>
                    <div class="our-team--swiper-arrow swiper-arrow">
                        <div class="our-team--swiper-button-prev swiper-button-prev"></div>  
                        <div class="our-team--swiper-button-next swiper-button-next"></div>  
                    </div>  
                </div>
            </swiper>
        </div>
    </section>
</template>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    name: "OurTeam",
    data() {
        return {
            members: [], 
        }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const onSwiper = (swiper) => {
        console.log(swiper);
      };
      const onSlideChange = () => {
        console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
        modules: [Navigation, Pagination, Scrollbar, A11y],
      };
    },
    methods: {
        createData() {
            if (this.$i18n.locale === "ua") {
                this.members = this.$i18n.messages.ua.our_team.members
            } else if (this.$i18n.locale === "en") {
                this.members = this.$i18n.messages.en.our_team.members
            }
        },
    },
    mounted () {
        this.createData()
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.createData()
            }
        }
    }
}
</script>

<style lang="sass">
.member-title
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 10px

    img
        margin-right: 20px
        width: 100px
        height: 100px
        border-radius: 50%
        object-fit: cover
        object-position: center

    .member-info
        .member-name 
            font-weight: 700
            margin-bottom: 5px
        .member-socials 
            display: flex
            justify-content: flex-start
            align-items: center
            & > li:not(:last-child)
                margin-right: 0.75rem
            i
                font-size: 1.75rem
                color: var(--secondary)
            a
                display: block
                line-height: 1rem
.member-content
    p
        font-size: calc(1rem - 2px)
    & > *:not(:last-child)
        margin-bottom: 5px

.member-title

    img
        width: 75px
        height: 75px
        margin-right: 10px

// .our-team--swiper-controller
//     display: none !important
// @media screen and (max-width: 1200px)
//     .our-team--swiper-controller
//         display: flex !important
</style>