<template>
  <section class="home-intro">
      <div class="container">
          <div class="row">

              <h1 :class="additionClassForTitle">
                <div><p>{{ $t('home_intro.h1') }}</p></div>
                <span><br></span>
                <div><span>{{ $t('home_intro.h1_span') }} </span></div>
              </h1>

              <ul>
                <li><h5>{{ $t('home_intro.ul.li1') }}</h5></li>
                <li><h5>{{ $t('home_intro.ul.li2') }}</h5></li>
                <li><h5>{{ $t('home_intro.ul.li3') }}</h5></li>
              </ul>
              <div class="intro-link">
                <a class="btn btn-light btn-lg" @click="scrollMeTo('who-we-are')">{{ $t('home_intro.links.href1') }}</a>
                <router-link class="btn btn-warning btn-lg" to="/donate">{{ $t('home_intro.links.href2') }}</router-link>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'HomeIntro',
  data() {
        return {
            additionClassForTitle: "ua"
        }
    },
  methods: {
    scrollMeTo(id) {
      var element = document.getElementById(id);
      var top = element.offsetTop - document.getElementsByTagName("header")[0].clientHeight - 1
      window.scrollTo(0, top);
    },
    createData() {
      if (this.$i18n.locale === "ua") {
          this.additionClassForTitle = "ua"
      } else if (this.$i18n.locale === "en") {
          this.additionClassForTitle = "en"
      }
    }
  },

  mounted () {
    this.createData()
  },

  watch: {
      '$i18n.locale': function(newVal, oldVal) {
          if (newVal !== oldVal) {
              this.createData()
          }
      }
  }
}
</script>

<style scoped lang="sass">
  section
    // background: var(--primary)
    color: #fff
    background: -webkit-linear-gradient(315deg,#33a06f 25%,#155f3e)
    padding: 120px 0
    position: relative
    text-shadow: -1px 0px 1px #000 , 1px 0px 1px #000 , 0px -1px 1px #000 , 0px 1px 1px #000

    &:after
      content: ""
      width: 100%
      height: 100%
      background: transparent
      position: absolute
      left: 0
      top: 0
      background-image: url("@/assets/intro-desk.jpg")
      background-size: cover
      background-position: top right
      opacity: 0.1
      z-index: 1

    .container
      position: relative
      z-index: 3

    h1
      margin-bottom: 40px
      font-weight: 800
      font-size: calc(1.375rem + 1.75vw)

      div
        border: 2px solid #fff
        display: inline-flex
        align-items: stretch
        font-weight: inherit

        p
          font-weight: inherit
          display: inline
          padding: 5px 10px
          
        span
          color: var(--secondary)
          font-weight: inherit
          border: 2px solid #fff
          background: #fff
          padding: 5px 10px
          text-shadow: 0 0 0 #000
          border-top: 0
        

    h2
      margin-bottom: 20px
      font-weight: 700
      line-height: 1.4

    ul 
      max-width: 620px

      > li:not(:last-child)
        margin-bottom: 20px

      > li h5 
        line-height: 1.6
        font-size: 1rem
        font-weight: 700

  .intro-link
    margin-top: 40px

    > a:not(:last-child)
      margin-right: 1.5rem
    
    a
      text-shadow: 0 0 0 #000

  @media screen and (max-width: 991px)
    
    section
      padding: 80px 0

      &:after
        background-image: url("@/assets/intro-mob.jpg")

      h1
        line-height: 1.4em
        font-size: calc(1.375rem + 4vw)
        text-align: center
        &.en
          font-size: calc(1.375rem + 2vw)

        & > span
          display: none

        div
          display: flex

          p
            display: block
            
          span
            display: block
            width: 100%
            font-size: calc(1.375rem + 6vw)

      ul
        margin: 0 auto

        > li h5 
          line-height: 1.6
          font-size: 1rem
          font-weight: 700

      .intro-link
        display: flex
        flex-direction: column 
        align-items: center

        > a:not(:last-child)
          margin-right: 0
          margin-bottom: 20px
        
        a
          min-width: 300px
</style>
