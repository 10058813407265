<template>
<header :class=" isOpen ? 'active' : '' ">
    <div class="container-fluid">
        <div class="row">
            <div class="logo">
                <router-link to="/">{{ $t('header.logo') }}</router-link>
            </div>
            <nav>
                <ul class="nav-page">
                    <li><router-link to="/">{{ $t('header.home') }}</router-link></li>
                    <li><router-link to="/donate">{{ $t('header.donate') }}</router-link></li>
                </ul>
                 <select class="form-select" v-model="$i18n.locale">
                    <option 
                        v-for="locale in $i18n.availableLocales" 
                        :key="`locale-${locale}`" 
                        :value="locale"
                    >{{ locale }}</option>
                </select>
            </nav>
            <div class="mobile-menu">
                <button class="btn btn-primary" @click="mobileMenu">
                    <i :class=" isOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-bars' "></i>
                </button>
            </div>
        </div>
    </div>
</header>
</template>

<script>
import i18n from 'vue-i18n'
export default {
    name: "MyHeader",
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        changeLocale(locale) {
            i18n.global.locale = locale;
        },
        mobileMenu(){
            this.$emit("isOpenChange", this.isOpen )
        }
    },
}
</script>

<style lang="sass" scoped>
    header
        background: #fff
        border-bottom: 1px solid #CED4DA
        color: #000
        padding: 10px 0
        position: fixed
        width: 100%
        top: 0
        z-index: 1000
        .logo a
            font-weight: 700
        a
            color: #000
            position: relative
            border-radius: 5px
            border: 1px solid transparent
            font-weight: 400
            
        .row
            display: flex
            justify-content: space-between
            align-items: center

            > *
                width: auto

        nav 
            display: flex
            align-items: center

            .nav-page
                display: flex
                align-items: center

                a
                    font-size: calc(1rem - 2px) 
                    display: block
                    padding: 0.375rem 0.75rem

                    &.router-link-exact-active
                        background: var(--primary)
                        border: 1px solid var(--primary)
                        color: #fff

            select
                margin-left: calc(40px + 0.75rem)
                font-size: calc(1rem - 2px)
                text-align: center

        .mobile-menu
            display: none

            button
                width: 40px
                height: 40px
                background: var(--primary)
                display: flex
                justify-content: center
                align-items: center

            i
                color: #fff

    @media screen and (max-width: 991px)

        header

            nav
                position: absolute
                top: 100%
                left: 0
                width: 100% !important
                flex-direction: column
                background: #fff
                border-bottom: 1px solid #CED4DA
                border-top: 1px solid #CED4DA
                padding: 20px
                display: none

                .nav-page
                    width: 100%
                    flex-direction: column

                    li
                        width: 100%
                        display: block

                        a
                            font-size: calc(1rem + 2px)
                            width: 100% !important
                            display: block

                select
                    margin-top: 20px
                    margin-left: 0
                    max-width: 120px
                    font-size: calc(1rem + 2px)

            &.active

                nav
                    display: flex
                    align-content: space-between
                    justify-content: space-between
                    
            .mobile-menu
                display: block
</style>
