<template>
  <div id="home-page">
    <home-intro />
    <who-we-are />
    <our-team class="gray"/>
    <impact-of-donation />
    <my-footer></my-footer>
  </div>
</template>

<script>
import HomeIntro from "@/components/HomeIntro.vue"
import WhoWeAre from "@/components/WhoWeAre.vue"
import OurTeam from "@/components/OurTeam.vue"
import ImpactOfDonation from "@/components/ImpactOfDonation.vue"
import MyFooter from "@/components/MyFooter.vue"

export default {
  name: 'HomeView',
  components: {
    HomeIntro , WhoWeAre , OurTeam , ImpactOfDonation , MyFooter
  }
}
</script>
