<template>
<div id="donate-page">
    <how-to-donate />
    <impact-of-donation class="gray"/>
    <my-footer></my-footer>
</div>
</template>

<script>
import HowToDonate from "@/components/HowToDonate.vue"
import ImpactOfDonation from "@/components/ImpactOfDonation.vue"
import MyFooter from "@/components/MyFooter.vue"

export default {
  name: 'HomeView',
  components: {
    HowToDonate , ImpactOfDonation , MyFooter
  }
}
</script>

<style scoped>

</style>