<template>
<div @click="isOpen = false">
  <my-header :isOpen="isOpen" @isOpenChange="mobileMenu" @click.stop></my-header>  
  <router-view/>
</div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue'

export default{
  data() {
    return {
      isOpen: false,
    }
  },
  components: {
    MyHeader
  },
  methods: {
    mobileMenu() {
        this.isOpen = !this.isOpen
    },
  },
  beforeCreate() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
}
</script>


<style lang="sass">
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap')

/*ui*/
html
  font-size: 18px

@media screen and (max-width: 991px)
  html
    font-size: 16px

body
  line-height: 1.6
  font-family: "Mulish", sans-serif
  color: var(--dark)
  font-weight: 400

h1, h2, h3, h4, h5, h6 , p , span , div , ul , li , a , nav
  margin: 0
  padding: 0
  text-decoration: none
  box-sizing: border-box
  font-weight: 400

ul
  list-style-type: none

section
  padding: 80px 0
  background: #fff

.section-title
    margin-bottom: 40px
    font-weight: 700

::-webkit-scrollbar
    display: none

 
.btn-primary
  background: var(--primary)
  border: 1px solid var(--primary)
.btn-outline-primary
  color: var(--primary-dark)
  border-color: var(--primary-dark)
  :hover
    color: #fff
    background-color: var(--primary-dark)
    border-color: var(--primary-dark)

.btn-warning
  background: var(--secondary-dark)
  border: 1px solid var(--secondary-dark)
  color: #fff
  &:hover
    color: #fff
    background-color: var(--secondary-dark)
    border-color: var(--secondary-dark)
.btn-outline-warning
  color: var(--secondary-dark)
  border-color: var(--secondary-dark)
  &:hover
    color: #fff
    background-color: var(--secondary-dark)
    border-color: var(--secondary-dark)

#app > div > div > section:first-child
  margin-top: 57.5px

@media screen and (max-width: 991px)
  #app > div > div > section:first-child
    margin-top: 61px

.gray
  background: var(--semi-white)

:root 
  // --primary: #3576F3

  --primary: #33a06f
  --secondary: #f9820d
  --semi-white: #f7f7f7
  --yellow: #fcf007
  --dark: #212529

  --vt-c-green-lighter: #35eb9a
  --vt-c-green-light: #42d392
  --vt-c-green: #42b883
  --vt-c-green-dark: #33a06f
  --vt-c-green-darker: #155f3e

  --vt-c-blue: #3b8eed
  --vt-c-blue-light: #549ced
  --vt-c-blue-lighter: #50a2ff
  --vt-c-blue-dark: #3468a3
  --vt-c-blue-darker: #255489

  --vt-c-yellow: #ffc517
  --vt-c-yellow-light: #ffe417
  --vt-c-yellow-lighter: #ffff17
  --vt-c-yellow-dark: #e0ad15
  --vt-c-yellow-darker: #bc9112

  --vt-c-red: #ed3c50
  --vt-c-red-light: #f43771
  --vt-c-red-lighter: #fd1d7c
  --vt-c-red-dark: #cd2d3f
  --vt-c-red-darker: #ab2131
  
  --vt-c-purple: #de41e0
  --vt-c-purple-light: #e936eb
  --vt-c-purple-lighter: #f616f8
  --vt-c-purple-dark: #823c83
  --vt-c-purple-darker: #602960

// swiper
.swiper-controller
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 40px

  & *
    position: relative !important
    margin-top: 0 !important
    margin-bottom: 0 !important

  .swiper-scrollbar 
    flex-grow: 1
    transform: translateY(3px)
    border-radius: 0.25rem
    height: 5px

    .swiper-scrollbar-drag
      background: var(--primary)
      height: 12px
      margin-top: -3px !important
      border-radius: 0.25rem

  .swiper-horizontal>.swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal
    left: 0
    width: 100%

  .swiper-arrow
    margin-left: 40px
    width: auto
    display: flex
    justify-content: center
    align-items: center

    .swiper-button-next
      margin-left: 20px

    .swiper-button-next, .swiper-button-prev
      margin-top: 0
      width: 30px
      max-width: 30px
      min-width: 30px
      height: 30px
      max-height: 30px
      min-height: 30px
      display: flex
      justify-content: center
      align-items: center
      background: var(--primary)
      color: #fff
      left: 0
      right: 0
      border-radius: 0.25rem

    .swiper-button-next:after, .swiper-button-prev:after
      font-size: 1rem
      font-weight: 900


// button
.btn-primary
  color: #fff !important
  background: var(--primary) !important
  border: 1px solid var(--primary) !important
  box-shadow: 0 0 0 0 rgba(51, 160, 111, 0.5) !important
  &:hover , &:active , &:focus
    color: #fff !important
    background: var(--primary) !important
    border: 1px solid var(--primary) !important
    box-shadow: 0 0 0 0.25rem rgba(51, 160, 111, 0.5) !important

.btn-warning
  color: #fff !important
  background: var(--secondary) !important
  border: 1px solid var(--secondary) !important
  box-shadow: 0 0 0 0 rgba(249, 130, 13, 0.5) !important
  &:hover , &:active , &:focus
    color: #fff !important
    background: var(--secondary) !important
    border: 1px solid var(--secondary) !important
    box-shadow: 0 0 0 0.25rem rgba(249, 130, 13, 0.5) !important

.btn-dark
  color: #fff !important
  background: #212529 !important
  border: 1px solid #212529 !important
  box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5) !important
  &:hover , &:active , &:focus
    color: #fff !important
    background: #212529 !important
    border: 1px solid #212529 !important
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5) !important

.btn-light
  color: #212529 !important
  background: #f8f9fa !important
  border: 1px solid #f8f9fa !important
  box-shadow: 0 0 0 0 rgba(248, 249, 250 , 0.5) !important
  &:hover , &:active , &:focus
    color: #212529 !important
    background: #f8f9fa !important
    border: 1px solid #f8f9fa !important
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250 , 0.5) !important
</style>
