<template>
    <section class="impact-of-donation">
        <div class="container">
            <div class="row">
                <h2 class="section-title">{{ $t("impact_of_donation.title") }}</h2>
                <div class="chart-container">
                    
                    <!-- <MonthlyChart 
                        class="chart-graph" 
                        :valuesInPercent="valuesInPercent"
                        :colors="colors"
                        :names="names"
                    /> -->

                    <!-- <div class="chart-legend">
                        <h3><strong>{{$t("impact_of_donation.chart_legend_title")}} :</strong> {{summ}} {{$t("impact_of_donation.currency")}}</h3>
                        <ul>
                            <li v-for="(item, index) in donation" :key="index">
                                <span :style="{ 'background': item.color }"></span>
                                <p><strong>{{item.name}} : </strong>{{item.value}} {{$t("impact_of_donation.currency")}} / {{valuesInPercent[index]}} %</p>
                            </li>
                        </ul>
                    </div> -->

                    <div class="cards">
                        <div class="card shadow" v-for="(item, index) in cards" :key="index">
                            <i :class="item.icon"></i>
                            <h5>{{item.strong}}</h5>
                            <p>{{item.p}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import MonthlyChart from '@/components/MonthlyChart.vue'

export default {
    name: "ImpactOfDonation",

    data() {
        return {
            donation: [],
            cards: [],

            names: [],
            values: [],
            colors: [],

            valuesInPercent: [],
            summ: 0,
        }
    },

    // components: {
    //     MonthlyChart
    // },

    methods: {
        createData() {

            this.donation = []
            this.cards = []

            this.names = []
            this.values = []
            this.colors = []

            this.valuesInPercent = []
            this.summ = 0

            if (this.$i18n.locale === "ua") {
                this.donation = this.$i18n.messages.ua.impact_of_donation.donation
                this.cards = this.$i18n.messages.ua.impact_of_donation.cards
            } else if (this.$i18n.locale === "en") {
                this.donation = this.$i18n.messages.en.impact_of_donation.donation
                this.cards = this.$i18n.messages.en.impact_of_donation.cards
            }

            this.donation.map(i => {
                this.names.push(i.name), 
                this.values.push(i.value),
                this.colors.push(i.color)
            })

            this.summ = this.values.reduce((a , b) => {
                return Number(a) + Number(b)
            })

            var sumDonationValues = this.values.reduce((a , b) => {
                return Number(a) + Number(b)
            })

            this.values.map((item) => {
                this.valuesInPercent.push( (Number(item) / sumDonationValues * 100).toFixed(2) )
            })
        }
    },

    mounted () {
        this.createData()
    },

    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.createData()
            }
        }
    }
}
</script>

<style lang="sass" scoped>
section
    .chart-container
        display: flex
        justify-content: space-between
        align-items: center
        flex-wrap: wrap

        .chart-graph
            width: calc(50% - 10px)

        .chart-legend
            width: calc(50% - 10px)

            h3
                margin-bottom: 20px

            ul > li:not(:last-child)
                margin-bottom: 5px

            li
                line-height: 1.5
                font-size: calc(1rem - 2px)

                p 
                    display: inline
                    line-height: inherit

                    strong
                        line-height: inherit

                > span
                    display: inline-block
                    border-radius: 50%
                    border: 2px solid #fff
                    width: 1rem
                    height: 1rem
                    margin-right: 0.25rem
                    margin-bottom: -0.25rem
                    line-height: inherit
            
        .cards
            // margin-top: 40px
            min-height: 100px
            width: 100%
            display: flex
            justify-content: center
            align-items: stretch
            flex-wrap: wrap

            .card
                width: calc( (100% - 40px) / 3 )
                border-radius: 0
                border: 0
                text-align: center
                padding: 20px
                border-radius: 0.25rem
                background: #fff
                color: #000
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column
                margin-right: 20px
                margin-bottom: 20px
                -webkit-transition: all 0.3s ease-in-out
                -o-transition: all 0.3s ease-in-out
                transition: all 0.3s ease-in-out
                &:hover 
                    background: var(--primary)
                    color: #fff
                    i
                        color: #fff
                &:last-child
                    margin-right: 0
                &:nth-child(3n + 3) 
                    margin-right: 0

                i 
                    font-size: 5vh 
                    margin-bottom: 0.5rem 
                    color: var(--primary)
                    -webkit-transition: all 0.3s ease-in-out
                    -o-transition: all 0.3s ease-in-out
                    transition: all 0.3s ease-in-out

                h5
                    font-weight: 700

                p
                    font-size: calc(1rem - 2px)

@media screen and (max-width: 991px)
    section
        .chart-container
            .chart-graph
                width: 100%
                max-width: 500px
                margin: 0 auto

            .chart-legend
                width: 100%
                margin: 40px auto 0 auto

            .cards
                // margin-top: 40px
                min-height: 100px
                width: 100%
                display: flex
                justify-content: center
                align-items: stretch
                flex-wrap: wrap

                .card
                    width: calc( (100% - 20px) / 2 )
                    margin-right: 20px
                    margin-bottom: 20px
                    &:last-child
                        margin-right: 0
                    &:nth-child(3n + 3) 
                        margin-right: 20px
                    &:nth-child(2n + 2) 
                        margin-right: 0

@media screen and (max-width: 767px)
    section
        .chart-container

            .chart-legend
                max-width: 500px

            .cards
                .card
                    width: 100%
                    margin-right: 0px
                    margin-bottom: 20px
                    &:last-child
                        margin-right: 0
                    &:nth-child(3n + 3) 
                        margin-right: 0
                    &:nth-child(2n + 2) 
                        margin-right: 0
                    &:not(:last-child)
                        margin-bottom: 20px
</style>