<template>
    <section class="who-we-are" id="who-we-are">
        <div class="container">
            <div class="row">
                <h2 class="section-title">{{ $t("who_we_are.title") }}</h2>
                <div class="cover-img">
                    <img src="@/assets/whoweare-team.jpg" alt="" class="shadow">
                </div>
                <div class="content">
                    <div class="shadow">
                        <p v-for="(p, index) in text" :key="index">{{p}}</p>
                        <p class="flag center"><strong>{{ $t("who_we_are.thx_message") }}</strong></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WhoWeAre",
    data() {
        return {
            text: [], 
        }
    },
    methods: {
        createData() {
            if (this.$i18n.locale === "ua") {
                this.text = this.$i18n.messages.ua.who_we_are.text
            } else if (this.$i18n.locale === "en") {
                this.text = this.$i18n.messages.en.who_we_are.text
            }
        },
    },
    mounted () {
        this.createData()
    },
    watch: {
        '$i18n.locale': function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.createData()
            }
        }
    }
}
</script>

<style lang="sass" scoped>
    section
        background: #fff
        .cover-img
            margin-bottom: -100px
            img
                width: 100%
                max-width: 100%
                display: block
                border-radius: 0.25rem
        .content
            >*:first-child > *:first-child
                margin-top: 0
            >*:last-child > *:last-child
                margin-bottom: 0
            > div
                margin: 0 auto
                padding: 40px
                border-radius: 0.25rem
                max-width: 900px
                background: #fff
                p
                    margin: 20px 0
                    &.center
                        text-align: center
                    &.flag
                        padding: 20px
                        color: #fff
                        font-weight: 900
                        border-radius: 0.25rem
                        text-shadow: 1px 1px 1px #000
                        margin-top: 40px
                        background: var(--primary)

@media screen and (max-width: 991px)
    section
        .cover-img
            margin-bottom: 40px

        .content
            > div
                padding: 0px
                max-width: 100%
                box-shadow: 0 0 0 rgba(0,0,0,0) !important

                p
                    &.flag

                        strong
                            display: inline-block
                            max-width: 300px
</style>
