<template>
    <footer>
        <div class="container">
            <div class="row">
                <h2 class="section-title">{{ $t("footer.title")}}</h2>
                <div>
                    <div>
                    <a href="https://docs.google.com/forms/d/1j99OETfXm1M-6rStNLcR1-BRZI4D_OgcSlIfZ6zY6Q0">
                        <p>{{ $t("footer.link1")}}</p>
                    </a>
                    </div>
                    <div>
                    <a  href="https://docs.google.com/forms/d/1BRjK2d4OttvJbnugH-CeD0OIw2yw9Ob5NpW2qrZEgqs">
                        <p>{{ $t("footer.link2")}}</p>
                    </a>
                    </div>
                    <div>
                    <a href="https://forms.gle/r6TZJYLLvujVNeDh9">
                        <p>{{ $t("footer.link3")}}</p>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "MyFooter",
    data() {
        return {

        }
    },  
    methods: {

    },
    mounted() {

    },
}
</script>

<style lang="sass" scoped>
footer
    background: var(--primary)
    padding: 80px 0
    color: #fff
    text-align: center
    position: relative
    
    div > div:not(:last-child)
        margin-bottom: 10px
    a
        color: #fff
        font-size: 1.2rem

        p
            font-weight: 600

        i
            width: 1rem
            display: inline-flex
            justify-content: center
            align-items: center
            margin-right: 0.25rem
            
</style>