<template>
    <section class="how-to-donate">   
        <div class="container">
            <div class="row">

                <div class="how-anchor">
                    <ul>
                        <li><button class="btn btn-primary" @click="scrollMeTo('card_transfer')">{{ $t("how_to_donate.anchor.href1") }}</button></li>
                        <li><button class="btn btn-primary" @click="scrollMeTo('cryptocurrency')">{{ $t("how_to_donate.anchor.href2") }}</button></li>
                        <li><button class="btn btn-primary" @click="scrollMeTo('swift')">{{ $t("how_to_donate.anchor.href3") }}</button></li>
                        <li><button class="btn btn-primary" @click="scrollMeTo('other')">{{ $t("how_to_donate.anchor.href4") }}</button></li>
                    </ul>
                </div>

                <h2 class="section-title">{{ $t("how_to_donate.title") }}</h2>

                <div class="methods">
                    <!-- <div class="method" ref="wihtin_ukraine">
                        <h3 class="method-title">UA Payments within Ukraine</h3>
                        <div class="method-currency">
                            <div class="method-description">
                                <p>CO Serhiy Prytula Charity Foundation EDRPOU 43720363</p>
                                <p>Purpose of payment "Charity donation”</p>
                            </div>
                            <div class="method-currency-refferal">
                                <a href="" class="btn btn-warning">Privat 24</a>
                                <a href="" class="btn btn-warning">Monobank</a>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">UAH</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="47 305299 00000 26005026707459">
                                <p>47 305299 00000 26005026707459</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Dollar</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="90 305299 00000 26001026709343">
                                <p>90 305299 00000 26001026709343</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Euro</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="87 305299 00000 26009016707067">
                                <p>87 305299 00000 26009016707067</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Polish zloty</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="893052990000026006046712337">
                                <p>893052990000026006046712337</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                    </div>--> <!-- method -->
                    <!-- <div class="method" ref="swift">
                        <h3 class="method-title">International SWIFT payment</h3>
                        <div class="method-currency">
                            <h5 class="method-currency-title">SWIFT in US dollars (USD)</h5>
                            <div class="method-description">
                                <p><strong>BENEFICIARY</strong>PRYTULA SERHIY</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="UA673052990000026200681955548">
                                <p><strong>IBAN</strong>UA673052990000026200681955548</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                            <div class="method-description">
                                <p><strong>ACCOUNT</strong>5168742060489527</p>
                                <p><strong>Beneficiary bank</strong>PRIVATBANK, 1D HRUSHEVSKOGO STR., KYIV, 01001, UKRAINE</p>
                                <p><strong>SWIFT CODE</strong>PBANUA2X</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank") }}</strong>P MORGAN CHASE BANK SWIFT CODE: CHASUS33</p>
                                <p><strong>Beneficiary bank’s account with Correspondent bank</strong>0011000080</p>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">SWIFT in euros (EUR)</h5>
                            <div class="method-description">
                                <p><strong>BENEFICIARY</strong>PRYTULA SERHIY</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="UA603052990000026202681911665">
                                <p><strong>IBAN</strong>UA603052990000026202681911665</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                            <div class="method-description">
                                <p><strong>ACCOUNT</strong>5168742060489444</p>
                                <p><strong>Beneficiary bank</strong>PRIVATBANK, 1D HRUSHEVSKOGO STR., KYIV, 01001, UKRAINE</p>
                                <p><strong>SWIFT CODE</strong>PBANUA2X</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank") }}</strong>Commerzbank AG Frankfurt am Main Germany</p>
                                <p><strong>SWIFT CODE</strong>COBADEFF</p>
                                <p><strong>Beneficiary bank’s account with Correspondent bank</strong>acc No 400 8867004 01</p>
                            </div>
                        </div>
                    </div>--> <!-- method -->
                    <!-- <div class="method" ref="card_transfer">
                        <h3 class="method-title">Card-to-card transfer in UAH</h3>
                        <div class="method-currency">
                            <div class="method-description">
                                <p>If the funds from this transfer are returned - try make a transfer across Ukraine to the account of the fund</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="5168 7420 6353 7207">
                                <p>5168 7420 6353 7207</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                            <div class="method-description">
                                <p><strong>Recipient</strong>Pritula S.D.</p>
                                <p><strong>Bill</strong>26200681993072</p>
                                <p><strong>IBAN</strong>UA843052990000026200681993072</p>
                                <p><strong>Beneficiary's bank</strong>JSC CB "PRIVATBANK", KYIV, UKRAINE</p>
                                <p><strong>EDRPOU of the recipient</strong>2975800618</p>
                            </div>
                        </div>
                    </div>--> <!-- method -->
                    
                    <div class="method" ref="card_transfer">
                        <h3 class="method-title">{{ $t("how_to_donate.methods.card_transfer.method_title") }}</h3>
                        <div class="method-currency">
                            <h5 class="method-currency-title">{{ $t("how_to_donate.methods.card_transfer.method_currency.mono.method_currency_title") }}</h5>
                            <div class="method-currency-refferal">
                                <a href="https://send.monobank.ua/jar/7sJQVQceZU" class="btn btn-warning" target="_blank">{{ $t("how_to_donate.methods.card_transfer.method_currency.mono.method_currency_refferal") }}</a>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">{{ $t("how_to_donate.methods.card_transfer.method_currency.privat.method_currency_title") }}</h5>
                            <div class="method-description">
                                <p>{{ $t("how_to_donate.methods.card_transfer.method_currency.privat.method_description") }}</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="5168745109975778">
                                <p>5168745109975778</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="method" ref="cryptocurrency">
                        <h3 class="method-title">{{ $t("how_to_donate.methods.cryptocurrency.method_title") }}</h3>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Bitcoin (BTC):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="bc1qp568vsp8u6rk97xekt53a77xhrenpnmztxacet">
                                <p>bc1qp568vsp8u6rk97xekt53a77xhrenpnmztxacet</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Tether (TRC20):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="TJcQqZf4ybLF94yLf4hf7bdhswC2dnY1Lx">
                                <p>TJcQqZf4ybLF94yLf4hf7bdhswC2dnY1Lx</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Ethereum (ETH):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="0x823d327219CCc888503c3C0c1145D1227A060C18">
                                <p>0x823d327219CCc888503c3C0c1145D1227A060C18</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Solana (SOL):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="HyEP7Vv1ge6m3DPCqAvQXawdbqKpFNDboXV3PmVp4ADQ">
                                <p>HyEP7Vv1ge6m3DPCqAvQXawdbqKpFNDboXV3PmVp4ADQ</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Binance coin (BEP20):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="bnb1lydduawe697xwut2gnup5xp68wv49968yesyql">
                                <p>bnb1lydduawe697xwut2gnup5xp68wv49968yesyql</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Litecoin (LTC):</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="ltc1q5q8m3p8ym627x8p5hqw6qp8042lqn5t3ux5he3">
                                <p>ltc1q5q8m3p8ym627x8p5hqw6qp8042lqn5t3ux5he3</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="method" ref="swift">
                        <h3 class="method-title">{{ $t("how_to_donate.methods.swift.method_title") }}</h3>
                        <div class="method-currency">
                            <h5 class="method-currency-title">{{ $t("how_to_donate.methods.swift.usd_method_currency_title") }}</h5>
                            <div class="method-description">
                                <p><strong>{{ $t("how_to_donate.methods.swift.company_name") }}</strong>ВИВЕЗЕМО! ГО</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="UA353133990000026005020205708">
                                <p><strong>IBAN</strong>UA353133990000026005020205708</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                            <hr>
                            <div class="method-description">
                                <p><strong>{{ $t("how_to_donate.methods.swift.bank_name") }}</strong>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.bank_swift_code") }}</strong>PBANUA2X</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.company_address") }}</strong>UA 69057 Запорiзька м Запорiжжя вул Незалежної України б.90а кв.17 </p>
                            </div>
                            <hr>
                            <div class="method-description">
                                <h6><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank_title") }}</strong></h6>
                                <p><strong>{{ $t("how_to_donate.methods.swift.account_correspondent_bank") }}</strong>001-1-000080</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.swift_code_correspondent_bank") }}</strong>CHASUS33</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank") }}</strong>JP Morgan Chase Bank, New York ,USA</p>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">{{ $t("how_to_donate.methods.swift.eur_method_currency_title") }}</h5>
                            <div class="method-description">
                                <p><strong>{{ $t("how_to_donate.methods.swift.company_name") }}</strong>ВИВЕЗЕМО! ГО</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="UA833133990000026008030204569">
                                <p><strong>IBAN</strong>UA833133990000026008030204569</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                            <hr>
                            <div class="method-description">
                                <p><strong>{{ $t("how_to_donate.methods.swift.bank_name") }}</strong>JSC CB "PRIVATBANK", 1D HRUSHEVSKOHO STR., KYIV, 01001, UKRAINE</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.bank_swift_code") }}</strong>PBANUA2X</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.company_address") }}</strong>UA 69057 Запорiзька м Запорiжжя вул Незалежної України б.90а кв.17 </p>
                            </div>
                            <hr>
                            <div class="method-description">
                                <h6><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank_title") }}</strong></h6>
                                <p><strong>{{ $t("how_to_donate.methods.swift.account_correspondent_bank") }}</strong>400886700401</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.swift_code_correspondent_bank") }}</strong>COBADEFF</p>
                                <p><strong>{{ $t("how_to_donate.methods.swift.correspondent_bank") }}</strong>Commerzbank AG, Frankfurt am Main, Germany</p>
                            </div>
                        </div>
                        <div class="method-currency">
                            <h5 class="method-currency-title">{{ $t("how_to_donate.methods.swift.uah_method_currency_title") }}</h5>
                            <div class="method-description">
                                <p><strong>{{ $t("how_to_donate.methods.swift.company_name") }}</strong>ВИВЕЗЕМО! ГО</p>
                            </div>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="UA093133990000026006040208900">
                                <p><strong>IBAN</strong>UA093133990000026006040208900</p>
                                <button class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                    </div>

                    <div class="method" ref="other">
                        <h3 class="method-title">{{ $t("how_to_donate.methods.other.method_title") }}</h3>
                        <div class="method-currency">
                            <h5 class="method-currency-title">Paypal</h5>
                            <div class="copy-block"  @click="copyValue" >
                                <input type="hidden" value="denisminin1@gmail.com">
                                <p>denisminin1@gmail.com</p>
                                <button  class="btn"><i class="fa-solid fa-copy"></i></button>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <div id="copied-to-clickboard">
            <p>Copied to clickboard</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "HowToDonate",
    methods: {
        scrollMeTo(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop - document.getElementsByTagName("header")[0].clientHeight - 1
            window.scrollTo(0, top);
        },
        copyValue(event) {
            // var copyText = event.target.childNodes[0]
            // copyText.select();
            // navigator.clipboard.writeText(copyText.value);

            let testingCodeToCopy = event.target.childNodes[0]
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Testing code was copied ' + msg);
            } catch (err) {
                console.log(err);
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()

            document.getElementById("copied-to-clickboard").style.visibility = "visible"
            setTimeout(() => {
                document.getElementById("copied-to-clickboard").style.visibility = "hidden"
            }, 2500)
        },
    },
}
</script>

<style lang="sass" scoped>
.how-anchor
    margin-bottom: 40px
    ul
        margin: -10px 0
        display: flex
        flex-wrap: wrap
        li
            margin: 10px 0
            &:not(:last-child)
                margin-right: 0.75rem
            button
                white-space: nowrap

.methods 
    display: flex
    justify-content: flex-start
    align-items: flex-start
    flex-wrap: wrap

    .method
        width: 100%
        padding: 20px 20px 0 20px
        border-radius: 0.25rem
        border: 1px solid #CED4DA
        overflow: hidden

        .method-title
            margin-bottom: 20px
            font-weight: 500

        .method-description
            margin: 10px 0
            font-size: calc(1rem - 2px)

            p
                margin: 10px 0

                strong
                    margin-right: 0.75rem

        .method-currency
            margin: 20px 0
            font-size: calc(1rem - 2px)
            background: var(--semi-white)
            border-radius: 0.25rem
            padding: 10px

            .method-currency-title
                font-weight: 500
                margin-bottom: 10px

            .method-currency-refferal 

                a:not(:last-child)
                    margin-right: 0.75rem

        .copy-block
            
            > * 
                pointer-events: none
            p
                max-width: 100%
                overflow: auto
                margin-right: 0.75rem
                word-break: break-all
                display: inline

                strong 
                    margin-right: 0.75rem

            button
                padding: 0
                box-shadow: 0 0 0 rgba(0,0,0,0) !important

                :active i
                    color: var(--secondary)

                i 
                    font-size: 1rem
                    color: var(--secondary)
                    margin-top: -4px
                    
    > .method:not(:last-child)
        margin-bottom: 40px

strong 
    font-weight: 500

#copied-to-clickboard
    position: fixed
    bottom: 20px
    left: 50%
    z-index: 10
    transform: translateX(-50%)
    padding: 10px 40px
    color: #fff
    background: rgba(0,0,0,.75)
    box-shadow: 0 0 0 .25rem rgba(0,0,0,.25)
    border-radius: 50px
    visibility: hidden
    font-size: calc(1rem - 2px)
    white-space: nowrap

@media screen and (max-width: 575px)
    .how-anchor
        
        ul
            margin: 0
            display: flex
            flex-wrap: wrap

            li
                width: 100%
                margin: 0

                &:not(:last-child)
                    margin-bottom: 0.75rem
                    margin-right: 0

                button
                    width: 100%
                
</style>